:root {
   --main-bg-color: #FFFFFF;
}
.dark :root {
   --main-bg-color: #000000;
}

body {
   font-family: 'Open Sans Condensed', sans-serif;
   padding: 20px 60px;
   background-color: var(--main-bg-color);
}

a {
   text-decoration: none;
   color: black;
}

* {
   box-sizing: border-box;
}